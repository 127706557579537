<template>
	<div class="model-field-editor-component">
		<div class="number">
			<div class="flex-box" v-if="!is_edit">
				<div class="val">{{ value }}</div>
				<i class="el-icon-edit icon" size="16" @click="onClickEdit" />
			</div>
			<div class="flex-box" v-else>
				<el-form @keydown.enter.native="onClickSubmit" @keydown.esc.native="is_edit = false">
					<el-input size="mini" class="edit" v-model="edit_value" ref="Input" :style="{ width: `${inputWidth}px` }" />
				</el-form>
				<el-button class="ml-1 flex-shrink-0" type="primary" size="mini" icon="el-icon-check" @click="onClickSubmit"></el-button
				><el-button
					class="ml-1 flex-shrink-0"
					type="error"
					size="mini"
					icon="el-icon-close
            "
					@click="is_edit = false"
				></el-button>
			</div>
		</div>
	</div>
</template>
<script>
/**
 * 编辑模型字段组件
 * 常用于Table中
 */
import { setModelField } from '@/api/other'
export default {
	props: {
		// 模型名
		model: {
			type: String,
			required: true,
		},
		// 模型ID
		modelId: {
			type: [String, Number],
			required: true,
		},
		// 字段名
		field: {
			type: String,
			required: true,
		},
		// 值，当修改成功后触发@input 修改value
		value: {
			required: true,
		},
		// 接口地址
		api: {
			type: Function,
			default: setModelField,
		},
		// 输入框宽度
		inputWidth: {
			type: [String, Number],
			default: 60,
		},
	},
	data() {
		return {
			is_edit: false,
			edit_value: null,
		}
	},
	methods: {
		onClickEdit() {
			this.edit_value = this.value
			this.is_edit = true
			this.$nextTick(() => {
				this.$refs.Input.focus({ cursor: 'all' })
			})
		},
		onClickSubmit() {
			this.api({
				model: this.model,
				id: this.modelId,
				field: this.field,
				value: this.edit_value,
			}).then((res) => {
				if (res.code != 0) {
					this.$Message.error(res.msg)
					return
				}

				this.$emit('input', res.data)
				this.$emit('change', res.data)
				this.is_edit = false
			})
		},
	},
	mounted() {},
}
</script>
<style scoped lang="scss">
.model-field-editor-component {
	.number {
		.icon {
			padding: 8px;
			cursor: pointer;
		}
	}
	.ml-1 {
		margin-left: 0.25rem !important;
	}
}
</style>
