





























































































































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { getRecharge, GiveCards } from '@/api/Recharge'
import SelectVipLv from '@/components/select/select-vip-lv.vue'
import { cloneDeep } from 'lodash'

export default Vue.extend({
	components: { SelectVipLv },
	data() {
		return {
			input_ilbe: false,
			dynamicValidateForm: {
				domains: [
					{
						value: '',
					},
				],
				email: '',
			},
			recharge_card: [],
			data_input: [],
			num: 0,
			handleClose: false,
			input: '',
			dialogVisible: false,
			textvisible: false,
			uid: 0,
			mobile: '',
			...useTable({
				name: '',
				formdata: {
					/** ID */
					id: 0,
					/** 名称 */
					name: '',
				},
				getData: (query) => {
					let n_query = cloneDeep(query)
					if (n_query['datetimes']) {
						n_query['start_time'] = n_query['datetimes'][0]
						n_query['end_time'] = n_query['datetimes'][1]
					}

					// console.log(n_query)

					// return getOrderHotelList(n_query).then((res) => res.data)

					return getRecharge(n_query).then((res) => res.data)
				},
			}),
		}
	},
	created() {
		this.tableGetData()
	},
	methods: {
		close() {
			this.dialogVisible = false
			this.dynamicValidateForm.domains = [
				{
					value: '',
				},
			]
		},
		cancel() {
			this.dialogVisible = false
			this.dynamicValidateForm.domains = [
				{
					value: '',
				},
			]
		},
		submitForm(formName) {
			;(this.$refs[formName] as any).validate((valid) => {
				if (valid) {
					console.log(this.dynamicValidateForm)
					let arr: any = []
					this.dynamicValidateForm.domains.map((itme) => {
						arr.push(Number(itme.value))
					})

					let data = {
						id: this.uid,
						cards: JSON.stringify(arr),
					}
					GiveCards(data).then((res) => {
						console.log(res)
						this.$message({
							message: '发卡成功！',
							type: 'success',
						})
						this.tableGetData()
					})

					this.dialogVisible = false
					this.input_ilbe = false
				} else {
					return false
				}
			})
		},
		resetForm(formName) {
			;(this.$refs[formName] as any).resetFields()
		},
		removeDomain(item) {
			var index = this.dynamicValidateForm.domains.indexOf(item)
			if (index !== -1) {
				this.dynamicValidateForm.domains.splice(index, 1)
			}
		},
		addDomain() {
			this.dynamicValidateForm.domains.push({
				value: '',
				//   key: Date.now()
			})
		},
		tuikuan(id: any, num: any, mobile: any) {
			console.log(mobile)
			this.mobile = mobile
			this.dialogVisible = true
			this.uid = id
			for (var i = 0; i < num - 1; i++) this.addDomain()
		},
		decodeUnicode(str) {
			str = str.replace(/\\/g, '%')
			// console.log(str, 'str')
			return unescape(str)
		},
		beforeClose() {},
		confirm() {},
		record(recharge_card: any) {
			this.textvisible = true
			this.recharge_card = recharge_card
		},
	},
})
