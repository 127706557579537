import request, { ResponsePageableData } from '@/libs/request'

/** 酒店标签列表 */
export const getHotelLabelList = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelLabel/getHotelLabelList', data })

/** 酒店标签新增/编辑 */
export const editHotelLabel = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelLabel/editHotelLabel', data })

/** 酒店标签删除 */
export const delHotelLabel = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelLabel/delHotelLabel', data })

/** 获取会员订单列表 */
export const getVipOrderList = (data?) => request<ResponsePageableData>({ url: '/adminapi/Vip/getVipOrderList', data })

/** 获取购买订单列表 */
export const getBalanceGoodList = (data?) => request<ResponsePageableData>({ url: 'adminapi/OrderChargeBalance/getOrderChargeBalanceList', data })
// 退款
export const delmemder = (data?) => request<ResponsePageableData>({ url: 'adminapi/vip/vipRefund', data })

// 会员认证信息审核
export const getUserAuthList = (data?) => request<ResponsePageableData>({ url: 'adminapi/user/getUserAuthList', data })

//会员信息审核
export const editUserAuth = (data?) => request<ResponsePageableData>({ url: 'adminapi/user/editUserAuth', data })
//会员重置
export const resettingUserAuth = (data?) => request<ResponsePageableData>({ url: 'adminapi/user/resettingUserAuth', data })
