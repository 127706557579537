













































































































































































































import Vue from 'vue'
import { mapState } from 'vuex'
import { useTable } from '@/components/table/table-hook'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import SelectActivityType from '@/components/select/select-camilo-type.vue'
import dialogSubmit from '@/components/form/dialog-submit.vue'
import SelectActivitystate from '@/components/select/select-camilo-state.vue'
import UploadImage from '@/components/upload/upload-image.vue'
import { getRechangeCardlist, getCardList, rechargeCard, fabrication, receive, distribute, delCard } from '@/api/Recharge'
import { getHotelList } from '@/api/hotel'
import { export_json_to_excel } from '@/vendor/Export2Excel'
import { cloneDeep } from 'lodash'
import elselectJs from '@/utils/elselectJs'
import SelectHotel from '@/components/select/select-hotel.vue' //酒店选择器
export default Vue.extend({
	mixins: [elselectJs],
	components: {
		SelectActivityType,
		SelectActivitystate,
		dialogSubmit,
		UploadImage,
		SelectHotel,
	},
	data() {
		return {
			makeCardDialog: false,
			finishMakeCardDialog: false,
			distributeCardDialog: false,
			dialog: false,
			export_data: [] as any,
			options: [] as any,
			hotelOptions: [] as any,
			makeCardForm: {
				name: '',
				company: '',
				remark: '',
			},
			finishMakeCardForm: {
				receive_user: '',
				remark: '',
			},
			distributeCardForm: {
				distribute_user: '',
				distribute_hotel: null,
				distribute_to_user: '',
				remark: '',
				image: '',
			},
			card_ids: [] as any,
			...useTable({
				name: '消息',
				formdata: {
					/** 名称 */
					notice_type: 1,
					content: '',
					vip_card_ids: [],
					is_app_push: 0,
				},
				getData: (query) => {
					let n_query = cloneDeep(query)
					if (n_query['datetimes']) {
						n_query['start_activation_time'] = n_query['datetimes'][0]
						n_query['end_activation_time'] = n_query['datetimes'][1]
					}
					return getRechangeCardlist(n_query).then((res) => res.data)
				},
				// delData: (row) => delCard({ id: row.id }),
			}),
		}
	},
	created() {
		this.tableGetData()
		this.getstate()
		this.getHotelList()
		console.log(this.admin_info)
		this.makeCardForm.name = this.admin_info.name
		this.finishMakeCardForm.receive_user = this.admin_info.name
		this.distributeCardForm.distribute_user = this.admin_info.name
	},
	computed: {
		...mapState<any>('admin', {
			admin_info: (state) => state.info,
		}),
	},
	methods: {
		closeMakeCard() {
			this.makeCardForm = {
				name: this.admin_info.name,
				company: '',
				remark: '',
			}
		},
		closeFinishMakeCard() {
			this.finishMakeCardForm = {
				receive_user: this.admin_info.name,
				remark: '',
			}
		},
		closeDistributeCard() {
			this.distributeCardForm = {
				distribute_user: this.admin_info.name,
				distribute_hotel: null,
				distribute_to_user: '',
				remark: '',
				image: '',
			}
		},
		deleteCard(row) {
			console.log(row.id)
			this.$confirm('确定要作废此卡吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					delCard({ card_id: row.id }).then((res) => {
						console.log(res)
						if (res.code == 0) {
							this.$message({
								type: 'success',
								message: '删除成功!',
							})
							this.tableGetData()
						}
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除',
					})
				})
		},
		getHotelList() {
			getHotelList().then((res) => {
				this.hotelOptions = res.data.rows
			})
		},
		handleSelectionChange(val) {
			var ids = [] as any
			val.forEach((it) => {
				ids.push(it.id)
			})
			this.card_ids = ids
		},
		makeCard() {
			if (this.card_ids.length == 0) {
				this.$message.error('请选择卡片')
				return
			}
			this.makeCardDialog = true
		},
		submitMakeCard() {
			let data = {
				card_ids: this.card_ids.toString(),
				// fabrication_user: this.makeCardForm.name,
				fabrication_company: this.makeCardForm.company,
				fabrication_remark: this.makeCardForm.remark,
			}
			fabrication(data).then((res) => {
				if (res.code == 0) {
					this.$message.success('操作成功')
					this.makeCardDialog = false
					this.makeCardForm = {
						name: this.admin_info.name,
						company: '',
						remark: '',
					}
					this.tableGetData()
				}
			})
		},
		completedMakeCard() {
			if (this.card_ids.length == 0) {
				this.$message.error('请选择卡片')
				return
			}
			this.finishMakeCardDialog = true
		},
		submitFinish() {
			let data = {
				card_ids: this.card_ids.toString(),
				// receive_user: this.finishMakeCardForm.receive_user,
				receive_remark: this.finishMakeCardForm.remark,
			}
			receive(data).then((res) => {
				if (res.code == 0) {
					this.$message.success('操作成功')
					this.finishMakeCardDialog = false
					this.finishMakeCardForm = {
						receive_user: this.admin_info.name,
						remark: '',
					}
					this.tableGetData()
				}
			})
		},
		distributeCard() {
			if (this.card_ids.length == 0) {
				this.$message.error('请选择卡片')
				return
			}
			this.distributeCardDialog = true
		},
		submitdistribute() {
			let data = {
				card_ids: this.card_ids.toString(),
				// distribute_user: this.distributeCardForm.distribute_user,
				distribute_hotel: this.distributeCardForm.distribute_hotel,
				distribute_to_user: this.distributeCardForm.distribute_to_user,
				distribute_remark: this.distributeCardForm.remark,
				distribute_image: this.distributeCardForm.image,
				// distribute_image: 'https://zhumanmanhotel.oss-cn-beijing.aliyuncs.com/zmm/image/ad/c2c3a91bd98d7561b63496a894142053e90dff.png',
			}
			console.log(data)
			distribute(data).then((res) => {
				if (res.code == 0) {
					this.$message.success('操作成功')
					this.distributeCardDialog = false
					this.distributeCardForm = {
						distribute_user: this.admin_info.name,
						distribute_hotel: null,
						distribute_to_user: '',
						remark: '',
						image: '',
					}
					this.tableGetData()
				}
			})
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map((v) => filterVal.map((j) => v[j]))
		},
		getstate() {
			getCardList().then((res) => {
				this.options = res.data.rows
			})
		},
		inportexcel() {
			this.dialog = true
		},
		onSubmit(row) {
			row.vip_card_ids = JSON.stringify(row.vip_card_ids)
			return rechargeCard(row).then(() => this.$message.success('保存成功') && this.tableGetData())
		},
		que() {
			if (this.table_query['state'] == 1) {
				this.dialog = false
				if (this.table_query['datetimes']) {
					this.table_query['start_activation_time'] = this.table_query['datetimes'][0]
					this.table_query['end_activation_time'] = this.table_query['datetimes'][1]
				}
				this.table_query['limit'] = 0
				getRechangeCardlist(this.table_query).then((res) => {
					this.export_data = res.data.rows
					this.table_query['limit'] = 10
					this.export_data.forEach((it) => {
						it.money = it.money / 100
						it.state =
							it.state == 1
								? '待制作'
								: it.state == 2
								? '制作中'
								: it.state == 3
								? '待分发'
								: it.state == 4
								? '待售卖'
								: it.state == 5
								? '已售卖'
								: it.state == 6
								? '已激活'
								: it.state == 7
								? '已删除'
								: ''
						// it.state = it.state == 1 ? '待售卖' : ''
					})
					//兼容ie10哦！
					require.ensure([], () => {
						const tHeader = ['卡号', '状态', '兑换码', '面额', '生成时间'] //将对应的属性名转换成中文
						// const tHeader = [];
						const filterVal = ['id', 'state', 'secret', 'money', 'create_time'] //table表格中对应的属性名
						const list = this.export_data
						const data = this.formatJson(filterVal, list)
						export_json_to_excel(tHeader, data, '充值卡号列表')
					})
				})
			} else {
				this.$message.error('非待制作，不能导出')
			}
		},
	},
})
