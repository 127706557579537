import request, { ResponsePageableData } from '@/libs/request'

/** 积分购买记录列表 */
export const getIntegralMallGoodList = (data?) => request<ResponsePageableData>({ url: 'adminapi/OrderChargeIntegral/getOrderChargeIntegralList', data })

/** 积分商城商品新增/编辑 */
export const editIntegralMallGood = (data?) => request<ResponsePageableData>({ url: '/adminapi/IntegralMallGood/editIntegralMallGood', data })

/** 积分商城商品删除 */
export const delIntegralMallGood = (data?) => request<ResponsePageableData>({ url: '/adminapi/IntegralMallGood/delIntegralMallGood', data })
