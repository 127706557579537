import request, { ResponsePageableData } from '@/libs/request'

//优惠券列表
export const getCouponList = (data?) => request<ResponsePageableData>({ url: '/adminapi/Coupon/getCouponList', data })

//编辑券列表
export const editCoupon = (data?) => request<ResponsePageableData>({ url: '/adminapi/Coupon/editCoupon', data })

//删除券列表
export const delCoupon = (data?) => request<ResponsePageableData>({ url: '/adminapi/Coupon/delCoupon', data })
